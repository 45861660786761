import React from 'react';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import { TEXT_GRADIENT } from 'pixi.js';
import debounce from 'lodash.debounce';
import {
    INIT_FONT_STYLE,
    COLOR_PICKER_DEBOUNCE_DELAY,
} from '../../../constants';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ColorFillItem() {
    const [colors, setColors] = useStyleSlice('fill');
    const delayedSetColors = debounce(setColors, COLOR_PICKER_DEBOUNCE_DELAY);

    return (
        <>
            <ColorsItem colors={colors} setColors={delayedSetColors} />

            {colors.length > 1 && (
                <>
                    <ColorStopsItem />
                    <GradientTypeItem />
                </>
            )}
        </>
    );
}

function ColorsItem({ colors, setColors }) {
    const addColor = () => {
        const currentState = [...colors, ...INIT_FONT_STYLE.fill];
        setColors(currentState);
    };

    const deleteColor = (index) => {
        const currentState = [...colors];
        currentState.splice(index, 1);
        setColors(currentState);
    };

    const handleColorChange = (index, e) => {
        const color = e.target.value;
        const currentState = [...colors];
        currentState[index] = color;
        setColors(currentState);
    };

    return (
        <>
            {colors.map((color, i) => (
                <ColorItem
                    color={color}
                    handleColorChange={handleColorChange}
                    deleteColor={deleteColor}
                    deleteDisabled={colors.length === 1}
                    index={i}
                    key={i}
                />
            ))}
            <Grid item xs={12}>
                <Button onClick={addColor} variant="outlined" fullWidth>
                    Add color
                </Button>
            </Grid>
        </>
    );
}

function ColorStopsItem() {
    const [fillGradientStops, setFillGradientStops] = useStyleSlice(
        'fillGradientStops'
    );

    const handleStopChange = (index, e) => {
        let val = e.target.valueAsNumber || 0;
        if (val > 1) {
            val = 1;
        }
        const currentState = [...fillGradientStops];
        currentState[index] = val;
        setFillGradientStops(currentState);
    };

    const addStop = () => {
        const currentState = [...fillGradientStops];
        currentState.push(0);
        setFillGradientStops(currentState);
    };

    const deleteColorStop = (index) => {
        const currentState = [...fillGradientStops];
        currentState.splice(index, 1);
        setFillGradientStops(currentState);
    };

    return (
        <>
            {fillGradientStops.map((stopNumber, i) => (
                <ColorStopItem
                    stopNumber={stopNumber}
                    handleStopChange={handleStopChange}
                    deleteColorStop={deleteColorStop}
                    index={i}
                    key={i}
                />
            ))}
            <Grid item xs={12}>
                <Button onClick={addStop} variant="outlined" fullWidth>
                    Add gradient color stop
                </Button>
            </Grid>
        </>
    );
}

function GradientTypeItem() {
    const [fillGradientType, setFillGradientType] = useStyleSlice(
        'fillGradientType'
    );
    const handleGradientTypeChange = (_, val) => {
        const value = Number(val);
        setFillGradientType(value);
    };

    return (
        <Grid item xs={12}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Gradient Type</FormLabel>
                <RadioGroup
                    name="gradientType"
                    row
                    value={fillGradientType}
                    onChange={handleGradientTypeChange}
                >
                    <FormControlLabel
                        value={TEXT_GRADIENT.LINEAR_HORIZONTAL}
                        control={<Radio color="default" />}
                        label="Horizontal"
                    />
                    <FormControlLabel
                        value={TEXT_GRADIENT.LINEAR_VERTICAL}
                        control={<Radio color="default" />}
                        label="Vertical"
                    />
                </RadioGroup>
            </FormControl>
        </Grid>
    );
}

function ColorStopItem({
    index,
    stopNumber,
    handleStopChange,
    deleteColorStop,
}) {
    return (
        <>
            <Grid item xs={11}>
                <TextField
                    label={`Color stop ${index + 1}`}
                    name={`color_stop_${index}`}
                    type="number"
                    value={stopNumber}
                    inputProps={{
                        min: 0,
                        max: 1,
                        step: 0.01,
                    }}
                    onChange={(e) => handleStopChange(index, e)}
                    variant="outlined"
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={() => deleteColorStop(index)}
                    variant="outlined"
                    component="span"
                >
                    <DeleteOutlineRoundedIcon />
                </IconButton>
            </Grid>
        </>
    );
}

function ColorItem({
    index,
    color,
    handleColorChange,
    deleteColor,
    deleteDisabled,
}) {
    return (
        <>
            <Grid item xs={11}>
                <TextField
                    label={`Color ${index + 1}`}
                    name={`color_${index}`}
                    type="color"
                    value={color}
                    onChange={(e) => handleColorChange(index, e)}
                    variant="outlined"
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    onClick={() => deleteColor(index)}
                    disabled={deleteDisabled}
                    variant="outlined"
                    component="span"
                >
                    <DeleteOutlineRoundedIcon />
                </IconButton>
            </Grid>
        </>
    );
}
