import { createSlice } from '@reduxjs/toolkit';
import { getPersistState } from './persistState';

const sliceName = 'includeConfig';
const initialState = getPersistState(sliceName, false);

export const includeConfigSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        toggleConfigInclusion: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { toggleConfigInclusion } = includeConfigSlice.actions;
export const selectConfigInclusion = (state) => state.includeConfig;
export default includeConfigSlice.reducer;
