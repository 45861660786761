import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function MiterLimitItem() {
    const [miterLimit, setMiterLimit] = useStyleSlice('miterLimit');
    const handleMLChange = (e) => {
        const value = e.target.valueAsNumber || 1;
        setMiterLimit(value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Miter limit`}
                name={`miter_limit`}
                type="number"
                value={miterLimit}
                onChange={handleMLChange}
                inputProps={{
                    min: 0,
                    step: 1,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
