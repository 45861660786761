import React from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { FONT_STYLE_OPTIONS } from '../../../constants';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function TextBaselineItem() {
    const [textBaseline, setBaseline] = useStyleSlice('textBaseline');
    const handleBaselineChange = (e) => {
        setBaseline(e.target.value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Text baseline`}
                name={`text_baseLine`}
                select
                value={textBaseline}
                onChange={handleBaselineChange}
                variant="outlined"
                fullWidth
                size="small"
            >
                {FONT_STYLE_OPTIONS.textBaseline.map((prop, i) => (
                    <MenuItem key={i} value={prop}>
                        {prop}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
    );
}
