import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ShadowAngleItem() {
    const [dropShadowAngle, setDropShadowAngle] = useStyleSlice(
        'dropShadowAngle'
    );
    const handleShadowAngleChange = (e) => {
        const value = e.target.valueAsNumber || 0;
        setDropShadowAngle(value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Angle`}
                name={`dropShadowAngle`}
                type="number"
                value={dropShadowAngle}
                onChange={(e) => handleShadowAngleChange(e)}
                inputProps={{
                    min: 0,
                    max: Math.PI * 2,
                    step: 0.02,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
