import React from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { useOptionSlice } from '../../../hooks/useOptionSlice';

export function PaddingControl() {
    const [padding, setPadding] = useOptionSlice('padding');
    const handlePaddingChange = (e) => {
        const value = e.target.valueAsNumber || 0;
        setPadding(value);
    };

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle name="Glyphs padding" />
            </Grid>
            <Grid item xs={12}>
                <Box pb={1}>
                    <TextField
                        label={`Padding`}
                        name={`texture_padding`}
                        type="number"
                        value={padding}
                        onChange={handlePaddingChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                        inputProps={{
                            min: 0,
                            step: 1,
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
}
