import React, { useState } from 'react';
import {
    Grid,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { TextureFillItem } from './TextureFillItem';
import { ColorFillItem } from './ColorFillItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const name = 'FillControl';

export default function FillControl({ expandedStyle, toggleOpenStyle }) {
    const [fillType, setFillType] = useState('Color');

    const handleFillTypeChange = (_, value) => {
        setFillType(() => value);
    };

    return (
        <Accordion
            expanded={expandedStyle === name}
            onChange={toggleOpenStyle(name)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`fillControl-header`}
            >
                <SectionTitle name="Fill" />
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Fill type</FormLabel>
                            <RadioGroup
                                name="fillType"
                                row
                                value={fillType}
                                onChange={handleFillTypeChange}
                            >
                                <FormControlLabel
                                    value="Color"
                                    control={<Radio color="default" />}
                                    label="Color"
                                />
                                <FormControlLabel
                                    value="Texture"
                                    control={<Radio color="default" />}
                                    label="Texture"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {fillType === 'Color' ? (
                        <ColorFillItem />
                    ) : (
                        <TextureFillItem />
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
