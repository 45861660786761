import React from 'react';
import { Grid, TextField, Box } from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { useOptionSlice } from '../../../hooks/useOptionSlice';

export function NameControl() {
    const [fontName, setFontName] = useOptionSlice('bitmapFontName');
    const handleFontNameChange = (e) => {
        if (e.target.value !== '') {
            setFontName(e.target.value);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle name="Font name" />
            </Grid>
            <Grid item xs={12}>
                <Box pb={1}>
                    <TextField
                        label={`Name`}
                        name={`font_name`}
                        type="text"
                        value={fontName}
                        onChange={handleFontNameChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                    />
                </Box>
            </Grid>
        </>
    );
}
