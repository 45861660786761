import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ShadowAlphaItem() {
    const [dropShadowAlpha, setDropShadowAlpha] = useStyleSlice(
        'dropShadowAlpha'
    );
    const handleShadowAlphaChange = (e) => {
        const value = e.target.valueAsNumber || 0;
        setDropShadowAlpha(value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Alpha`}
                name={`dropShadowAlpha`}
                type="number"
                value={dropShadowAlpha}
                onChange={handleShadowAlphaChange}
                inputProps={{
                    min: 0,
                    max: 1,
                    step: 0.01,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
