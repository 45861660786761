import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Canvas from './Canvas';
import Controls from './Controls';
import MobileWarning from './MobileWarning';
import { isMobile } from '../utils';

const useStyles = makeStyles(() => ({
    editor: {
        width: '100vw',
        position: 'fixed',
        top: '5vh',
        borderTop: '4px solid #303030',
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '11px',
        },
        '*::-webkit-scrollbar-corner': {
            background: '#424242',
        },
        '*::-webkit-scrollbar-track': {
            background: '#424242',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#303030',
            borderRadius: '6px',
            border: '3px solid #303030',
        },
    },
}));

export default function Editor() {
    const classes = useStyles();
    if (isMobile()) {
        return <MobileWarning />;
    }
    return (
        <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            className={classes.editor}
            // style={{
            //     width: '100vw',
            //     position: 'fixed',
            //     top: '5vh',
            //     borderTop: '4px solid #303030',
            // }}
        >
            <Controls />
            <Canvas />
        </Grid>
    );
}
