import React, { useEffect, useRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { createPIXIApp } from '../pixi/app';
import { useSelector } from 'react-redux';
import { selectStyle, selectOptions } from '../redux/settingsSlice';
import { selectMetaFileExtension } from '../redux/fileExtSlice';
import { emit } from '../EventEmitter';
import { EVENTS } from '../constants';

const useStyles = makeStyles(() => ({
    canvasWrapper: {
        height: '91vh',
        backgroundColor: '#424242',
        overflow: 'auto',
    },
}));

export default function Canvas() {
    const classes = useStyles();
    const canvasRef = useRef();
    const options = useSelector(selectOptions);
    const style = useSelector(selectStyle);
    const metaFileExt = useSelector(selectMetaFileExtension);

    useEffect(() => {
        createPIXIApp(canvasRef.current);
    }, []);

    useEffect(() => {
        emit(EVENTS.EXT_CHANGE, { metaFileExt });
    }, [metaFileExt]);

    useEffect(() => {
        emit(EVENTS.SETTINGS_CHANGE, { style, options });
    }, [options, style]);

    return (
        <Grid item sm={9} className={classes.canvasWrapper}>
            <canvas ref={canvasRef} />
        </Grid>
    );
}
