import React from 'react';
import { Grid, Button, FormControlLabel, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectPSDInclusion,
    togglePSDInclusion,
} from '../../redux/includePSDSlice';
import {
    selectConfigInclusion,
    toggleConfigInclusion,
} from '../../redux/includeConfigSlice';
import { emit } from '../../EventEmitter';
import { EVENTS } from '../../constants';

export function DownloadPanel() {
    const includePSD = useSelector(selectPSDInclusion);
    const includeConfig = useSelector(selectConfigInclusion);
    const dispatch = useDispatch();

    const handlePSD = (e) => {
        dispatch(togglePSDInclusion(e.target.checked));
    };

    const handleConfig = (e) => {
        dispatch(toggleConfigInclusion(e.target.checked));
    };

    const generate = () => emit(EVENTS.GENERATE, { includePSD, includeConfig });

    return (
        <>
            <Grid item xs={12}>
                <Button fullWidth variant="outlined" onClick={generate}>
                    Download font
                </Button>
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={includePSD}
                            onChange={handlePSD}
                            name="includePSD"
                            color="default"
                        />
                    }
                    label="include PSD"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={includeConfig}
                            onChange={handleConfig}
                            name="includeConfig"
                            color="default"
                        />
                    }
                    label="include config"
                />
            </Grid>
        </>
    );
}
