import React, {
    createContext,
    useContext,
    forwardRef,
    useRef,
    useEffect,
    Children,
    isValidElement,
    cloneElement,
} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import {
    Grid,
    TextField,
    useMediaQuery,
    ListSubheader,
    Button,
} from '@material-ui/core';
import { useGoogleFonts } from '../../../hooks/useGoogleFonts';
import { LIST_BOX_PADDING } from '../../../constants';

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const ListBoxComponent = forwardRef(function ListBoxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LIST_BOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];

export function FontFamilyItem() {
    const classes = useStyles();
    const [
        availableFonts,
        selectedFont,
        handleFontSelection,
        handleFontUpload,
    ] = useGoogleFonts();

    const handleSelection = (_, val, reason) => {
        if (reason === 'select-option') {
            handleFontSelection(val);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <Autocomplete
                    id="availableFonts"
                    disableListWrap
                    classes={classes}
                    ListboxComponent={ListBoxComponent}
                    renderGroup={renderGroup}
                    options={availableFonts}
                    value={selectedFont}
                    onChange={handleSelection}
                    getOptionLabel={(fontName) => fontName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose Google or generic font"
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" component="label" fullWidth>
                    Upload Local Font
                    <input
                        type="file"
                        value={''}
                        onChange={handleFontUpload}
                        hidden
                        accept=".ttf, .otf, .woff, .woff2"
                    />
                </Button>
            </Grid>
        </>
    );
}

function renderRow(props) {
    const { data, index, style } = props;
    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LIST_BOX_PADDING,
        },
    });
}

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}
