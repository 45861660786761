import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { useStyleSlice } from '../../../hooks/useStyleSlice';
import { COLOR_PICKER_DEBOUNCE_DELAY } from '../../../constants';

export function ShadowColorItem() {
    const [dropShadowColor, setShadowColor] = useStyleSlice('dropShadowColor');
    const delayedSetColor = debounce(
        setShadowColor,
        COLOR_PICKER_DEBOUNCE_DELAY
    );
    const handleShadowColorChange = (e) => {
        delayedSetColor(e.target.value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Color`}
                name={`dropShadowColor`}
                type="color"
                value={dropShadowColor}
                onChange={handleShadowColorChange}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
