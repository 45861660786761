import { useState } from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: blueGrey,
    },
});

const lightTheme = createMuiTheme({
    palette: {
        primary: blueGrey,
    },
});

export function useAppTheme() {
    const [theme, setTheme] = useState(darkTheme);

    const setAppTheme = (type) => {
        const t = type === 'dark' ? darkTheme : lightTheme;
        setTheme(t);
    };

    return [theme, setAppTheme];
}
