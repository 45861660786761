import { writePsd } from 'ag-psd';
import { Sprite } from 'pixi.js';

export function createPSD(
    pixiApp,
    font,
    textureWidth,
    textureHeight,
    pageIndex
) {
    const pageChars = Object.keys(font.chars)
        .map((id) => ({
            charObj: font.chars[id],
            letter: String.fromCodePoint(+id),
        }))
        .filter((item) => {
            return pageIndex === item.charObj.page;
        });

    const psdTemplate = {
        width: textureWidth,
        height: textureHeight,
    };
    psdTemplate.children = pageChars.reduce((layers, item) => {
        const { charObj, letter } = item;
        const { x, y, width, height } = charObj.texture.orig;
        const bottom = y + height;
        const right = x + width;
        const letterLayer = {
            name: letter,
            canvas: extractCanvas(pixiApp, charObj.texture),
            top: y,
            left: x,
            bottom,
            right,
        };
        const boundsLayer = {
            name: `${letter}_bounds`,
            hidden: true,
            canvas: createBoundCanvas(width, height),
            top: y,
            left: x,
            bottom,
            right,
        };
        return [...layers, letterLayer, boundsLayer];
    }, []);
    const buffer = writePsd(psdTemplate);
    return new Blob([buffer], { type: 'application/octet-stream' });
}

function createBoundCanvas(width, height) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = '#424242';
    ctx.lineWidth = 2;
    ctx.strokeRect(0, 0, width, height);
    return canvas;
}

function extractCanvas(pixiApp, texture) {
    const sprite = new Sprite(texture);
    return pixiApp.renderer.plugins.extract.canvas(sprite);
}
