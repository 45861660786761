import { TEXT_GRADIENT, BitmapFont } from 'pixi.js';

export const PERSIST_STATE_DEBOUNCE_DELAY = 300;
export const COLOR_PICKER_DEBOUNCE_DELAY = 100;

export const FONT_API_KEY = 'AIzaSyAMHHTuO3LIzsGHZS8C1_mqCb2hx-1IRY4';

export const SHORTENER_API_KEY = '85e88495490047ec96d1338f083c9703';

export const MAIL_END_POINT =
    'https://pixijs-apps-mail.netlify.app/.netlify/functions/send-mail';

export const SHORTENER_END_POINT = 'https://api.rebrandly.com/v1/links';

export const CONFIG_PARAM_NAME = 'config';

export const LIST_BOX_PADDING = 8;

export const TITLE = {
    FONT: 'Sigmar One',
    TEXT: 'BITMAP FONT GENERATOR',
};

export const EVENTS = {
    FONT_STYLE_CHANGE: 'font-style-change',
    SETTINGS_CHANGE: 'settings-change',
    GENERATE: 'generate',
    EXT_CHANGE: 'ext-change',
    REDRAW: 'redraw',
};

export const CHARS_RANGES = {
    lowerCaseLatin: ['a', 'z'],
    upperCaseLatin: ['A', 'Z'],
    numeric: ['0', '9'],
    space: ' ',
};

export const INIT_SETTINGS = {
    bitmapFontName: 'MyFont',
    chars: BitmapFont.ALPHANUMERIC,
    padding: 6,
    resolution: 1,
    textureHeight: 512,
    textureWidth: 1024,
};

export const TEXTURE_SIZE_OPTIONS = [64, 128, 256, 512, 1024, 2048, 4096];

export const GENERIC_FONT_FAMILIES = [
    'fantasy',
    'system-ui',
    'serif',
    'sans-serif',
    'monospace',
    'cursive',
];

export const FONT_STYLE_OPTIONS = {
    lineJoin: ['miter', 'round', 'bevel'],
    textBaseline: [
        'alphabetic',
        'bottom',
        'middle',
        'top',
        'hanging',
        'ideographic',
    ],
    fontWeight: [
        'normal',
        'bold',
        'bolder',
        'lighter',
        '100',
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
    ],
};

export const PATTERN_REPEAT = ['repeat', 'repeat-x', 'repeat-y', 'no-repeat'];

export const INIT_FONT_STYLE = {
    dropShadow: false,
    dropShadowDistance: 5,
    dropShadowColor: '#000000',
    dropShadowBlur: 0,
    dropShadowAngle: Math.PI / 6,
    dropShadowAlpha: 1,

    fill: ['#303030'],
    fillGradientStops: [],
    fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,

    fontFamily: GENERIC_FONT_FAMILIES[0],
    fontSize: 80,
    fontWeight: FONT_STYLE_OPTIONS.fontWeight[0],
    textBaseline: FONT_STYLE_OPTIONS.textBaseline[0],

    lineJoin: FONT_STYLE_OPTIONS.lineJoin[0],
    miterLimit: 10,
    stroke: '#F2F2F2',
    strokeThickness: 0,
};
