import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import settingsReducer from './settingsSlice';
import charsRangesReducer from './charsRangesSlice';
import expandedStyleReducer from './expandedStyleSlice';
import openSectionReducer from './openSectionSlice';
import metaFileExtReducer from './fileExtSlice';
import includePSDReducer from './includePSDSlice';
import includeConfigReducer from './includeConfigSlice';
import { PERSIST_STATE_DEBOUNCE_DELAY } from '../constants';
import { createPersistStateProvider } from './persistState';

export const store = configureStore({
    reducer: {
        settings: settingsReducer,
        charRangesOptions: charsRangesReducer,
        expandedStyle: expandedStyleReducer,
        openSection: openSectionReducer,
        metaFileExtension: metaFileExtReducer,
        includePSD: includePSDReducer,
        includeConfig: includeConfigReducer,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActionPaths: ['payload.value'],
            ignoredPaths: ['settings.style.fill'],
        },
    }),
});

const setPersistState = createPersistStateProvider(store);
store.subscribe(debounce(setPersistState, PERSIST_STATE_DEBOUNCE_DELAY));
