import { createSlice } from '@reduxjs/toolkit';
import { getPersistState } from './persistState';

const sliceName = 'charRangesOptions';
const initialState = getPersistState(sliceName, {
    isLowerLatin: true,
    isUpperLatin: true,
    isNumeric: true,
    isSpace: true,
    otherCharacters: '!@#$%^&*()~{}[]',
});

export const charsRangesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        toggleOption: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        setOtherChars: (state, action) => {
            state.otherCharacters = action.payload;
        },
    },
});

export const { toggleOption, setOtherChars } = charsRangesSlice.actions;
export const selectRangesOptions = (state) => state.charRangesOptions;
export default charsRangesSlice.reducer;
