import React from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { FONT_STYLE_OPTIONS } from '../../../constants';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function FontWeightItem() {
    const [fontWeight, setFontWeight] = useStyleSlice('fontWeight');

    const handleFontWeightChange = (e) => {
        setFontWeight(e.target.value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Weight`}
                name={`font_weight`}
                select
                value={fontWeight}
                onChange={handleFontWeightChange}
                variant="outlined"
                fullWidth
                size="small"
            >
                {FONT_STYLE_OPTIONS.fontWeight.map((prop, i) => (
                    <MenuItem key={i} value={prop}>
                        {prop}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
    );
}
