import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadGoogleFont } from '../hooks/useGoogleFonts';
import { TITLE } from '../constants';

const { FONT, TEXT } = TITLE;

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100vw',
        color: '#FF4D4D',
    },
    header: {
        position: 'fixed',
        width: '100vw',
        height: '5vh',
        color: theme.palette.text.secondary,
        backgroundColor:
            theme.palette.type === 'dark'
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
    },
}));

export default function Header() {
    const classes = useStyles();
    const canvasRef = useRef();

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        const compStyles = window.getComputedStyle(canvasRef.current);
        const height = parseInt(compStyles.getPropertyValue('height'));
        const width = window.innerWidth;
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        drawTitle(ctx, 'sans-serif', width, height);

        loadGoogleFont(FONT).then(() => {
            drawTitle(ctx, FONT, width, height);
        });
    }, []);

    return (
        <canvas
            ref={canvasRef}
            width="500"
            height="48"
            className={classes.header}
        />
    );
}

function drawTitle(ctx, font, w, h) {
    const fs = Math.round(h * 0.8);
    const offset = 2;
    const highLight = 'rgba(90,90,90,0.9)';
    const shadow = 'rgba(0,0,0,0.6)';
    const x = w / 2;
    const y = h / 2 + 4;

    ctx.clearRect(0, 0, w, h);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `${fs}px "${font}"`;
    ctx.shadowBlur = offset;

    draw(shadow, offset);
    draw(highLight, -offset);

    ctx.shadowColor = 'rgba(0,0,0,0.0)';
    ctx.fillStyle = 'black';
    ctx.globalCompositeOperation = 'destination-out';
    ctx.fillText(TEXT, x, y);
    ctx.globalCompositeOperation = 'source-over';

    function draw(color, offset) {
        ctx.fillStyle = ctx.shadowColor = color;
        ctx.shadowOffsetY = ctx.shadowOffsetX = offset;
        ctx.fillText(TEXT, x, y);
    }
}
