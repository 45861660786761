import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOptionsChange, selectOptions } from '../redux/settingsSlice';

export function useOptionSlice(key) {
    const options = useSelector(selectOptions);
    const dispatch = useDispatch();

    const handleOptions = (value) => {
        const payload = {
            key,
            value,
        };
        dispatch(handleOptionsChange(payload));
    };
    const memoized = useCallback(handleOptions, [key, dispatch]);

    return [options[key], memoized];
}
