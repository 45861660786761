import { INIT_FONT_STYLE } from './constants';

export function resolveCharacters(chars) {
    if (typeof chars === 'string') {
        chars = [chars];
    }
    const result = [];

    for (let i = 0, j = chars.length; i < j; i++) {
        const item = chars[i];
        if (Array.isArray(item)) {
            const startCode = item[0].charCodeAt(0);
            const endCode = item[1].charCodeAt(0);

            for (let i = startCode, j = endCode; i <= j; i++) {
                result.push(String.fromCharCode(i));
            }
        } else {
            result.push(...item.split(''));
        }
    }

    return result;
}

export function normalizeString(str) {
    return str.replace(/\s+/g, '');
}

export function getUniqueChars(str) {
    return getUniqueItems([...str]);
}

export function getUniqueItems(arr) {
    return [...new Set(arr)];
}

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function isArraysEqual(arr1, arr2) {
    return arr1.every((item, i) => item === arr2[i]);
}

export function serialize(obj) {
    return JSON.stringify(obj, replacer, 4);
}

function replacer(key, value) {
    if (key === 'fill' && value instanceof CanvasPattern) {
        return INIT_FONT_STYLE.fill;
    }
    return value;
}

export function encodeData(data) {
    const json = JSON.stringify(data);
    const encoded = btoa(json);
    return encodeURIComponent(encoded);
}

export function decodeData(str) {
    const decoded = atob(decodeURIComponent(str));
    let data = null;
    try {
        data = JSON.parse(decoded);
    } catch (e) {}
    return data;
}

export function isMobile() {
    let hasTouchScreen;
    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        const mediaQuery = matchMedia && matchMedia('(pointer:coarse)');
        if (mediaQuery && mediaQuery.media === '(pointer:coarse)') {
            hasTouchScreen = !!mediaQuery.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true;
        } else {
            const { userAgent } = navigator;
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(userAgent);
        }
    }
    return hasTouchScreen;
}
