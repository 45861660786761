import { Application, Sprite, TextStyle, BitmapFont, Graphics } from 'pixi.js';
import { saveAs } from 'file-saver';
import { createXML } from '../xml/create-xml';
import { createPSD } from '../psd/create-psd';
import { EVENTS } from '../constants';
import { listen } from '../EventEmitter';
import { serialize } from '../utils';

let bitmapFontName = 'MyFont';
let font = null;
let app = null;
let storedConfig = null;
let canvases = [];
let fileExt = 'xml';
const gap = 4;

listen(EVENTS.GENERATE, handleFontGeneration);
listen(EVENTS.EXT_CHANGE, (data) => {
    fileExt = data.metaFileExt;
});

function handleFontGeneration({ includePSD, includeConfig }) {
    saveXML(bitmapFontName, font, fileExt);
    if (includeConfig) {
        saveConfig(bitmapFontName, storedConfig, fileExt);
    }
    canvases.forEach((canvas, i) => {
        const suffix = i === 0 ? '' : `_${i}`;
        if (includePSD) {
            savePSD(bitmapFontName, i, suffix, fileExt, app, canvas, font);
        }
        canvas.toBlob((blob) => {
            saveAs(blob, `${bitmapFontName}${suffix}.png`);
        });
    });
}

export function createPIXIApp(view) {
    const compStyles = window.getComputedStyle(view.parentNode);
    let height = parseInt(compStyles.getPropertyValue('height')) - 8;
    let width = parseInt(compStyles.getPropertyValue('width')) - 8;
    app = new Application({
        width,
        height,
        view,
        backgroundAlpha: 0,
    });

    listen(EVENTS.SETTINGS_CHANGE, drawBitmapFont);
    listen(EVENTS.REDRAW, () => {
        if (storedConfig) {
            drawBitmapFont(storedConfig);
        }
    });

    function drawBitmapFont({ style, options }) {
        storedConfig = { style, options };
        app.stage.removeChildren();
        bitmapFontName = options.bitmapFontName;
        const textStyle = new TextStyle(style);
        const { textureHeight, textureWidth } = options;
        font = BitmapFont.from(bitmapFontName, textStyle, options);

        let x = 0;
        let y = 0;
        let i = 0;

        const positions = [];

        const width_ = textureWidth > width ? textureWidth : width;
        let height_ = textureHeight > height ? textureHeight : height;

        while (font.pageTextures[i]) {
            positions.push({ x, y });
            x += textureWidth + gap;
            if (x + textureWidth + gap > width_) {
                x = 0;
                y += textureHeight + gap;
            }
            i++;
        }

        if (positions[positions.length - 1].y + textureHeight + gap > height_) {
            height_ = positions[positions.length - 1].y + textureHeight + gap;
        }

        if (height_ !== height || width_ !== width) {
            width = width_;
            height = height_;
            app.renderer.resize(width, height);
        }

        canvases = positions.map((pos, i) => {
            const { x, y } = pos;
            const bg = new Graphics();
            bg.beginFill(0x464646);
            bg.drawRect(x, y, textureWidth, textureHeight);
            bg.endFill();
            app.stage.addChild(bg);
            const sprite = new Sprite(font.pageTextures[i]);
            sprite.position.set(x, y);
            app.stage.addChild(sprite);
            return font.pageTextures[i].baseTexture.resource.source;
        });
    }
}

function saveConfig(name, storedConfig, fileExt) {
    const config = Object.assign({}, storedConfig, {
        metaFileExtension: fileExt,
        application: window.location.origin,
    });
    const json = serialize(config);
    const jsonBlob = new Blob([json], {
        type: 'application/json;charset=utf-8',
    });
    saveAs(jsonBlob, `${name}.config.json`);
}

function saveXML(name, font, fileExt) {
    const xmlString = createXML(name, font);
    const xmlBlob = new Blob([xmlString], {
        type: 'text/xml;charset=utf-8',
    });
    saveAs(xmlBlob, `${name}.${fileExt}`);
}

function savePSD(name, index, suffix, fileExt, app, canvas, font) {
    const psdBlob = createPSD(app, font, canvas.width, canvas.height, index);
    saveAs(psdBlob, `${name}${suffix}.psd`);
}
