import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { CHARS_RANGES } from '../../../constants';
import {
    normalizeString,
    getUniqueChars,
    resolveCharacters,
} from '../../../utils';
import {
    toggleOption,
    setOtherChars,
    selectRangesOptions,
} from '../../../redux/charsRangesSlice';
import { useOptionSlice } from '../../../hooks/useOptionSlice';

function useChars() {
    // eslint-disable-next-line no-unused-vars
    const [_, handleOptions] = useOptionSlice('chars');
    const options = useSelector(selectRangesOptions);
    const dispatch = useDispatch();

    const handleCharsSelectionChange = ({ target }) => {
        if (target.name === 'otherCharacters') {
            dispatch(setOtherChars(target.value));
        } else {
            const payload = {
                key: target.name,
                value: target.checked,
            };
            dispatch(toggleOption(payload));
        }
    };

    useEffect(() => {
        const rangesAndChars = convertSelectionToRanges(options);
        handleOptions(rangesAndChars);
    }, [options, handleOptions]);

    return [options, handleCharsSelectionChange];
}

export function CharactersControl() {
    const [options, handleCharsSelectionChange] = useChars();

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle name="Characters" />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={options.isLowerLatin}
                            onChange={handleCharsSelectionChange}
                            name="isLowerLatin"
                            color="default"
                        />
                    }
                    label="lower case latin"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={options.isUpperLatin}
                            onChange={handleCharsSelectionChange}
                            name="isUpperLatin"
                            color="default"
                        />
                    }
                    label="upper case latin"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={options.isNumeric}
                            onChange={handleCharsSelectionChange}
                            name="isNumeric"
                            color="default"
                        />
                    }
                    label="numeric"
                />
            </Grid>

            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={options.isSpace}
                            onChange={handleCharsSelectionChange}
                            name="isSpace"
                            color="default"
                        />
                    }
                    label="space"
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Other characters"
                    multiline
                    rows={3}
                    rowsMax={8}
                    name="otherCharacters"
                    value={options.otherCharacters}
                    onChange={handleCharsSelectionChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{ spellCheck: false }}
                />
            </Grid>
        </>
    );
}

function convertSelectionToRanges(selection) {
    const result = [];
    const {
        isLowerLatin,
        isUpperLatin,
        isNumeric,
        isSpace,
        otherCharacters,
    } = selection;

    if (isLowerLatin) {
        result.push(CHARS_RANGES.lowerCaseLatin);
    }
    if (isUpperLatin) {
        result.push(CHARS_RANGES.upperCaseLatin);
    }
    if (isSpace) {
        result.push(CHARS_RANGES.space);
    }
    if (isNumeric) {
        result.push(CHARS_RANGES.numeric);
    }

    const currentChars = resolveCharacters(result);
    const addCharacters = getUniqueChars(normalizeString(otherCharacters));
    addCharacters.forEach((char) => {
        if (!currentChars.includes(char)) {
            result.push(char);
        }
    });
    return result;
}
