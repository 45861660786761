import React, { useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    TextField,
    Popover,
    Typography,
} from '@material-ui/core';
import { MAIL_END_POINT } from '../../constants';

const initFormData = {
    name: '',
    email: '',
    app: 'bitmap-font',
    content: '',
};

export function MessageForm() {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(initFormData);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverText, setPopoverText] = useState('');
    const formLauncherRef = useRef();

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const isPopoverShown = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };
    const handleClose = () => {
        setData((prevState) => ({ ...prevState, content: '' }));
        setOpen(false);
    };

    const handleForm = (e) => {
        const val = e.target.value;
        const prop = e.target.name;
        setData((prevState) => ({ ...prevState, [prop]: val }));
    };

    const handleSend = () => {
        handleClose();
        postData(data).then((isOK) => {
            if (isOK) {
                setPopoverText('Message sent. Thank you');
            } else {
                setPopoverText('Not sent: server error');
            }
            setAnchorEl(formLauncherRef.current);
        });
    };

    return (
        <>
            {' | '}
            &nbsp;&nbsp;
            <Link
                href="#"
                onClick={handleClickOpen}
                ref={formLauncherRef}
                color="inherit"
            >
                Feedback & issues
            </Link>
            <Popover
                id={id}
                open={isPopoverShown}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: '12px' }}>
                    {popoverText}
                </Typography>
            </Popover>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Feedback & Issues</DialogTitle>
                <DialogContent>
                    <TextField
                        name="name"
                        autoFocus
                        inputProps={{ spellCheck: 'false' }}
                        margin="dense"
                        value={data.name}
                        onChange={handleForm}
                        label="Your name"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        name="email"
                        inputProps={{ spellCheck: 'false' }}
                        margin="dense"
                        value={data.email}
                        onChange={handleForm}
                        label="Your email"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        name="content"
                        label="Message"
                        multiline
                        rows={3}
                        rowsMax={8}
                        value={data.content}
                        onChange={handleForm}
                        variant="outlined"
                        fullWidth
                        inputProps={{ spellCheck: false }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={!isMessageDataValid(data)}
                        onClick={handleSend}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

async function postData(data) {
    const response = await fetch(MAIL_END_POINT, getRequestInit(data));
    return response.ok;
}

function getRequestInit(data) {
    return {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };
}

function isMessageDataValid({ name, email, content }) {
    return !(isEmpty(name) || !isValidEmail(email) || isEmpty(content));
}

function isEmpty(str) {
    return str === '';
}

function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
