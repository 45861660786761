export function emit(eventType, data) {
    const event = new CustomEvent(eventType, { detail: data });
    window.dispatchEvent(event);
}

export function listen(eventType, listener) {
    window.addEventListener(
        eventType,
        function (e) {
            listener(e.detail);
        },
        false
    );
}
