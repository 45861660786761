import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenSection, selectOpenSection } from '../redux/openSectionSlice';
import { OptionsPanel } from './controls/OptionsPanel';
import { StylePanel } from './controls/StylePanel';
import { DownloadPanel } from './controls/DownloadPanel';
import { ShareLinkPanel } from './controls/ShareLinkPanel';
import { UploadConfigPanel } from './controls/UploadConfigPanel';
import { Grid, Tabs, Tab, Box } from '@material-ui/core';
import { useGoogleFonts } from '../hooks/useGoogleFonts';

export default function Controls() {
    /**
     * to trigger Google font download if 'OptionsPanel' opened
     */
    // eslint-disable-next-line
    const [_, font] = useGoogleFonts();
    console.log(font);

    const activeIndex = useSelector(selectOpenSection);
    const dispatch = useDispatch();

    const toggleOpenSection = (_, index) => {
        dispatch(setOpenSection(index));
    };

    return (
        <Grid item xs={3} style={{ height: '91vh', overflow: 'auto' }}>
            <Box p={1}>
                <Grid container spacing={1}>
                    <DownloadPanel />
                    <UploadConfigPanel />
                    <ShareLinkPanel />
                </Grid>

                <Tabs
                    value={activeIndex}
                    onChange={toggleOpenSection}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Generator options" id="control-tab-0" />
                    <Tab label="Font style" id="control-tab-1" />
                </Tabs>
                <TabPanel value={activeIndex} index={0}>
                    <OptionsPanel />
                </TabPanel>
                <TabPanel value={activeIndex} index={1}>
                    <StylePanel />
                </TabPanel>
            </Box>
        </Grid>
    );
}

function TabPanel({ children, value, index }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`control-tabpanel-${index}`}
            aria-labelledby={`control-tab-${index}`}
        >
            {value === index && { ...children }}
        </div>
    );
}
