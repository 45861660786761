import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    canvasWrapper: {
        height: '96vh',
        width: '100vw',
        backgroundColor: '#424242',
        overflow: 'auto',
    },
}));

export default function MobileWarning() {
    const classes = useStyles();

    return (
        <Grid item xs={10}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.canvasWrapper}
            >
                <Grid item sm={12}>
                    <Typography align="center" variant="h5">
                        This app is not for mobile usage
                    </Typography>

                    <Typography align="center" variant="h5">
                        Please get back with a desktop browser
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
