import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { FontFamilyItem } from './FontFamilyItem';
import { FontSizeItem } from './FontSizeItem';
import { FontWeightItem } from './FontWeightItem';
import { TextBaselineItem } from './TextBaselineItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const name = 'FontControl';

export default function FontControl({ expandedStyle, toggleOpenStyle }) {
    return (
        <Accordion
            expanded={expandedStyle === name}
            onChange={toggleOpenStyle(name)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`fontControl-header`}
            >
                <SectionTitle name="Font" />
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <FontFamilyItem />
                    <FontSizeItem />
                    <FontWeightItem />
                    <TextBaselineItem />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
