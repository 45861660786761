import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ShadowDistanceItem() {
    const [dropShadowDistance, setMiterLimit] = useStyleSlice(
        'dropShadowDistance'
    );
    const handleShadowDistanceChange = (e) => {
        const value = e.target.valueAsNumber || 0;
        setMiterLimit(value);
    };
    // TODO: check negative values
    return (
        <Grid item xs={12}>
            <TextField
                label={`Distance`}
                name={`dropShadowDistance`}
                type="number"
                value={dropShadowDistance}
                onChange={handleShadowDistanceChange}
                inputProps={{
                    step: 1,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
