import { useDispatch, useSelector } from 'react-redux';
import {
    selectOptions,
    selectStyle,
    setOptions,
    setStyle,
} from '../redux/settingsSlice';
import { CHARS_RANGES, INIT_FONT_STYLE } from '../constants';
import { setMetaFileExtension } from '../redux/fileExtSlice';
import { setOtherChars, toggleOption } from '../redux/charsRangesSlice';
import { isArraysEqual } from '../utils';

export function useExternalConfig() {
    const storedOptions = useSelector(selectOptions);
    const storedStyle = useSelector(selectStyle);
    const dispatch = useDispatch();

    return (config) => {
        const { options, style, metaFileExtension } = config;
        if (!Array.isArray(style.fill)) {
            style.fill = INIT_FONT_STYLE.fill;
        }
        const updatedStyle = Object.assign({}, storedStyle, style);
        const updatedOptions = Object.assign({}, storedOptions, options);
        dispatch(setStyle(updatedStyle));
        dispatch(setOptions(updatedOptions));
        dispatch(setMetaFileExtension(metaFileExtension));
        const rangeOptions = convertRangesToSelection(updatedOptions.chars);
        dispatch(setOtherChars(rangeOptions.otherCharacters));

        Object.entries(rangeOptions).forEach(([key, value]) => {
            if (key === 'otherCharacters') {
                dispatch(setOtherChars(value));
            } else {
                const payload = {
                    key,
                    value,
                };
                dispatch(toggleOption(payload));
            }
        });
    };
}

function convertRangesToSelection(rangesAndChars) {
    const options = {
        isLowerLatin: false,
        isUpperLatin: false,
        isNumeric: false,
        isSpace: false,
        otherCharacters: '',
    };
    rangesAndChars.forEach((item) => {
        if (typeof item === 'string') {
            if (item === CHARS_RANGES.space) {
                options.isSpace = true;
            } else if (item.length === 1) {
                options.otherCharacters += item;
            }
        } else if (Array.isArray(item) && item.length === 2) {
            let flag = getRangeFlag(item);
            if (flag) {
                options[flag] = true;
            }
        }
    });
    return options;

    function getRangeFlag(arr) {
        let flag;
        switch (true) {
            case isArraysEqual(arr, CHARS_RANGES.upperCaseLatin):
                flag = 'isUpperLatin';
                break;
            case isArraysEqual(arr, CHARS_RANGES.lowerCaseLatin):
                flag = 'isLowerLatin';
                break;
            case isArraysEqual(arr, CHARS_RANGES.numeric):
                flag = 'isNumeric';
                break;
            default:
                flag = null;
        }
        return flag;
    }
}
