import { createSlice } from '@reduxjs/toolkit';
import { INIT_FONT_STYLE, INIT_SETTINGS } from '../constants';
import { getPersistState } from './persistState';

const sliceName = 'settings';
const initialState = getPersistState(sliceName, {
    style: INIT_FONT_STYLE,
    options: INIT_SETTINGS,
});

export const settingsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        handleStyleChange: (state, action) => {
            const { key, value } = action.payload;
            state.style[key] = value;
        },
        handleOptionsChange: (state, action) => {
            const { key, value } = action.payload;
            state.options[key] = value;
        },
        setStyle: (state, action) => {
            state.style = action.payload;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
    },
});

export const {
    handleStyleChange,
    handleOptionsChange,
    setStyle,
    setOptions,
} = settingsSlice.actions;
export const selectStyle = (state) => state.settings.style;
export const selectFontSize = (state) => state.settings.style.fontSize;
export const selectOptions = (state) => state.settings.options;
export default settingsSlice.reducer;
