import { useState, useCallback } from 'react';
const memoryState = {};

export function useMemoryState(key, initialState) {
    const [state, setState] = useState(() => {
        const hasMemoryValue = Object.prototype.hasOwnProperty.call(
            memoryState,
            key
        );
        if (hasMemoryValue) {
            return memoryState[key];
        } else {
            return typeof initialState === 'function'
                ? initialState()
                : initialState;
        }
    });

    const onChange = (nextState) => {
        memoryState[key] = nextState;
        setState(nextState);
    };

    const memoized = useCallback(onChange, [key]);

    return [state, memoized];
}
