import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, MenuItem, TextField } from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { useOptionSlice } from '../../../hooks/useOptionSlice';
import { TEXTURE_SIZE_OPTIONS } from '../../../constants';
import { selectFontSize } from '../../../redux/settingsSlice';
const HEIGHT_GROW_FACTOR = 1.1;

export function SizeControl() {
    const minSize = useSelector(selectFontSize) * HEIGHT_GROW_FACTOR;
    const [textureHeight, setTextureHeight] = useOptionSlice('textureHeight');
    const [textureWidth, setTextureWidth] = useOptionSlice('textureWidth');
    const handleHeightChange = (e) => {
        const value = e.target.value;
        if (Number(value) > minSize) {
            setTextureHeight(value);
        }
    };
    const handleWidthChange = (e) => {
        const value = e.target.value;
        if (Number(value) > minSize) {
            setTextureWidth(value);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle name="Texture size" />
            </Grid>
            <Grid item xs={6}>
                <Box pb={1}>
                    <TextField
                        label={`Width`}
                        name={`textureWidth`}
                        select
                        value={textureWidth}
                        onChange={handleWidthChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                    >
                        {TEXTURE_SIZE_OPTIONS.map((prop, i) => (
                            <MenuItem key={i} value={prop}>
                                {prop}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box pb={1}>
                    <TextField
                        label={`Height`}
                        name={`textureHeight`}
                        select
                        value={textureHeight}
                        onChange={handleHeightChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                    >
                        {TEXTURE_SIZE_OPTIONS.map((prop, i) => (
                            <MenuItem key={i} value={prop}>
                                {prop}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Grid>
        </>
    );
}
