import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, MenuItem } from '@material-ui/core';
import { useMemoryState } from '../../../hooks/useMemoryState';
import { useStyleSlice } from '../../../hooks/useStyleSlice';
import { PATTERN_REPEAT } from '../../../constants';

export function TextureFillItem() {
    // eslint-disable-next-line no-unused-vars
    const [_, setColors] = useStyleSlice('fill');
    const [memory, onMemoryChange] = useMemoryState('TextureFill', {
        uploaded: {},
        selected: '',
    });
    const [repeatMemory, onRepeatMemoryChange] = useMemoryState(
        'PatternRepeat',
        PATTERN_REPEAT[0]
    );
    const [patternRepeat, setPatternRepeat] = useState(repeatMemory);
    const [uploadedImages, setUploadedImages] = useState(memory.uploaded);
    const [selectedImage, setSelectedImage] = useState(memory.selected);
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        getAsDataURL(file).then((dataUrl) => {
            setUploadedImages((prevState) => ({
                ...prevState,
                [file.name]: dataUrl,
            }));
            setSelectedImage(() => file.name);
        });
    };

    const handleImageSelect = (e) => {
        const image = e.target.value;
        setSelectedImage(() => image);
    };
    const handlePatternRepeat = (e) => {
        const value = e.target.value;
        setPatternRepeat(value);
        onRepeatMemoryChange(value);
    };

    useEffect(() => {
        if (selectedImage !== '') {
            createPattern(uploadedImages[selectedImage], patternRepeat).then(
                (pattern) => {
                    setColors(pattern);
                }
            );
            onMemoryChange({
                uploaded: uploadedImages,
                selected: selectedImage,
            });
        }
    }, [
        selectedImage,
        uploadedImages,
        patternRepeat,
        onMemoryChange,
        setColors,
    ]);

    return (
        <>
            {Object.keys(uploadedImages).length > 0 && (
                <>
                    <Grid item xs={12}>
                        <TextField
                            id="foobar"
                            select
                            label="Images"
                            value={selectedImage}
                            onChange={handleImageSelect}
                            variant="outlined"
                            fullWidth
                            size="small"
                        >
                            {Object.keys(uploadedImages).map((imageName, i) => (
                                <MenuItem key={i} value={imageName}>
                                    {imageName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={`Pattern repetition`}
                            name={`patternRepeat`}
                            select
                            value={patternRepeat}
                            onChange={handlePatternRepeat}
                            variant="outlined"
                            fullWidth
                            size="small"
                        >
                            {PATTERN_REPEAT.map((prop, i) => (
                                <MenuItem key={i} value={prop}>
                                    {prop}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <Button variant="outlined" component="label" fullWidth>
                    Upload Image
                    <input
                        type="file"
                        value={''}
                        onChange={handleImageUpload}
                        hidden
                    />
                </Button>
            </Grid>
        </>
    );
}

function createPattern(imageData, repeat) {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const pattern = ctx.createPattern(imageData, repeat);
        resolve(pattern);
    });
}

function getAsDataURL(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.readAsDataURL(file);

        function onReaderLoad(e) {
            const img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.src = e.target.result;
        }
    });
}
