import { createSlice } from '@reduxjs/toolkit';
import { getPersistState } from './persistState';

const sliceName = 'includePSD';
const initialState = getPersistState(sliceName, false);

export const includePSDSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        togglePSDInclusion: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { togglePSDInclusion } = includePSDSlice.actions;
export const selectPSDInclusion = (state) => state.includePSD;
export default includePSDSlice.reducer;
