import React from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ShadowEnableItem() {
    const [dropShadow, setDropShadow] = useStyleSlice('dropShadow');
    const handleDropShadowChange = (e) => {
        setDropShadow(e.target.checked);
    };

    return (
        <Grid item xs={12}>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dropShadow}
                            onChange={handleDropShadowChange}
                            name="dropShadow"
                            color="default"
                        />
                    }
                    label="Enable"
                />
            </FormGroup>
        </Grid>
    );
}
