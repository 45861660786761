import { serialize } from '../utils';

export function getPersistState(itemName, defaultValue) {
    let state = defaultValue;
    try {
        const serialized = localStorage.getItem(itemName);
        if (serialized) {
            state = JSON.parse(serialized);
        }
    } catch (e) {}
    return state;
}

export function createPersistStateProvider(store) {
    return function setPersistState() {
        const state = store.getState();
        Object.keys(state).forEach((key) => {
            localStorage.setItem(key, serialize(state[key]));
        });
    };
}
