import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { useExternalConfig } from '../../hooks/useExternalConfig';

export function UploadConfigPanel() {
    const setExternalConfig = useExternalConfig();

    const uploadConfig = (e) => {
        const file = e.target.files[0];
        readTextFile(file).then((json) => {
            let data = null;
            try {
                data = JSON.parse(json);
            } catch (e) {
                console.log(e);
            }

            if (data) {
                setExternalConfig(data);
            }
        });
    };

    return (
        <Grid item xs={6}>
            <Box pb={2} pt={1}>
                <Button fullWidth variant="outlined" component="label">
                    Upload config
                    <input
                        type="file"
                        value={''}
                        onChange={uploadConfig}
                        hidden
                        accept="application/json"
                    />
                </Button>
            </Box>
        </Grid>
    );
}

function readTextFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.readAsText(file);
    });
}
