import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function StrokeWidthItem() {
    const [thickness, setThickness] = useStyleSlice('strokeThickness');
    const handleColorChange = (e) => {
        const value = e.target.valueAsNumber || 0;
        setThickness(value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Stroke thickness`}
                name={`stroke_thickness`}
                type="number"
                value={thickness}
                onChange={handleColorChange}
                inputProps={{
                    min: 0,
                    step: 1,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
