import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectStyle, selectOptions } from '../../redux/settingsSlice';
import { selectMetaFileExtension } from '../../redux/fileExtSlice';
import {
    Grid,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    FormControl,
    OutlinedInput,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SHORTENER_API_KEY, SHORTENER_END_POINT, CONFIG_PARAM_NAME } from '../../constants';
import { encodeData } from '../../utils';

export function ShareLinkPanel() {
    const [open, setOpen] = useState(false);
    const [titleText, setTitleText] = useState('Copy project URL');
    const [url, setUrl] = useState('');
    const options = useSelector(selectOptions);
    const style = useSelector(selectStyle);
    const metaFileExtension = useSelector(selectMetaFileExtension);

    const handleClose = () => {
        setUrl('');
        setOpen(false);
        setTitleText('Copy project URL');
    };

    const handleCopy = () => {
        setTitleText('Copied to clipboard');
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const triggerConfig = () => {
        createShortUrl(options, style, metaFileExtension).then((url) => {
            setOpen(true);
            setUrl(url);
        });
    };

    return (
        <Grid item xs={6}>
            <Box pb={2} pt={1}>
                <Button fullWidth variant="outlined" onClick={triggerConfig}>
                    Share project
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogContent>
                    <Box pb={2}>
                        <Typography align="center" variant="button">
                            {titleText}
                        </Typography>
                    </Box>

                    <FormControl fullWidth size="small" variant="outlined">
                        <OutlinedInput
                            value={url}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CopyToClipboard text={url} onCopy={handleCopy}>
                                        <IconButton
                                            title="Copy to clipboard"
                                            onMouseDown={handleMouseDown}
                                            edge="end"
                                        >
                                            <FileCopyIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function createShortUrl(options, style, metaFileExtension) {
    const config = Object.assign(
        {},
        { style },
        { options },
        { metaFileExtension },
        {
            application: isLocalHost() ? 'https://bitmap-font.com' : window.location.origin,
        }
    );
    const encodedConfig = encodeData(config);
    const longURL = `${config.application}/?${CONFIG_PARAM_NAME}=${encodedConfig}`;
    const linkRequest = {
        destination: longURL,
        domain: { fullName: 'rebrand.ly' },
        slashtag: createSlashTag(options.bitmapFontName),
    };
    const requestHeaders = {
        'Content-Type': 'application/json',
        apikey: SHORTENER_API_KEY,
    };

    return postData(SHORTENER_END_POINT, requestHeaders, linkRequest)
        .then((data) => `https://${data.shortUrl}`)
        .catch((e) => {
            console.log(e);
            return longURL;
        });
}

function isLocalHost() {
    return document.domain === 'localhost';
}

async function postData(url = '', headers, data) {
    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    });
    return response.json();
}

function createSlashTag(fontName) {
    const rand = Date.now().toString().slice(-5);
    return `${fontName}-${rand}`;
}
