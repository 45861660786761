import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { CharactersControl } from './settings-controls/CharactersControl';
import { NameControl } from './settings-controls/NameControl';
import { SizeControl } from './settings-controls/SizeControl';
import { ResolutionControl } from './settings-controls/ResolutionControl';
import { PaddingControl } from './settings-controls/PaddingControl';
import { MetaFileExtensionControl } from './settings-controls/MetaFileExtensionControl';

export function OptionsPanel() {
    return (
        <div style={{ padding: '16px' }}>
            <Grid container spacing={1} justify="center" alignItems="center">
                <NameControl />
                <CharactersControl />
                <Grid item xs={12}>
                    <Typography align="center" variant="body2">
                        <Link
                            color="inherit"
                            target="_blank"
                            href="https://en.wikipedia.org/wiki/List_of_Unicode_characters"
                        >
                            Get special characters
                        </Link>
                    </Typography>
                </Grid>
                <SizeControl />
                <PaddingControl />
                <ResolutionControl />
                <MetaFileExtensionControl />
            </Grid>
        </div>
    );
}
