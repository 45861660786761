import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { COLOR_PICKER_DEBOUNCE_DELAY } from '../../../constants';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ColorStrokeItem() {
    const [color, setColor] = useStyleSlice('stroke');
    const delayedSetColor = debounce(setColor, COLOR_PICKER_DEBOUNCE_DELAY);
    const handleColorChange = (e) => {
        delayedSetColor(e.target.value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Stroke color`}
                name={`stroke_color`}
                type="color"
                value={color}
                onChange={handleColorChange}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
