import { createSlice } from '@reduxjs/toolkit';
import { getPersistState } from './persistState';

const sliceName = 'openSection';
const initialState = getPersistState(sliceName, 0);

export const openSectionSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setOpenSection: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setOpenSection } = openSectionSlice.actions;
export const selectOpenSection = (state) => state.openSection;
export default openSectionSlice.reducer;
