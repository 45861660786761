import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function FontSizeItem() {
    const [fontSize, setFontSize] = useStyleSlice('fontSize');

    const handleFontSizeChange = (e) => {
        const value = e.target.valueAsNumber || 1;
        setFontSize(value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Size`}
                name={`font_size`}
                type="number"
                value={fontSize}
                onChange={handleFontSizeChange}
                inputProps={{
                    min: 1,
                    step: 1,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
