import { prettifyXml } from './prettify-xml';

export function createXML(fontName, font) {
    const doc = document.implementation.createDocument('', '', null);
    const fontElem = doc.createElement('font');
    doc.appendChild(fontElem);

    const infoElem = doc.createElement('info');
    infoElem.setAttribute('face', fontName);
    infoElem.setAttribute('size', font.size.toString());
    infoElem.setAttribute('bold', '0');
    infoElem.setAttribute('italic', '0');
    fontElem.appendChild(infoElem);

    const commonElem = doc.createElement('common');
    commonElem.setAttribute('lineHeight', font.size.toString());
    commonElem.setAttribute('base', font.size.toString()); // ?
    commonElem.setAttribute('scaleW', font.pageTextures[0].height.toString());
    commonElem.setAttribute('scaleH', font.pageTextures[0].width.toString());
    const pagesNumber = Object.keys(font.pageTextures).length;
    commonElem.setAttribute('pages', pagesNumber.toString());
    commonElem.setAttribute('packed', '0');
    fontElem.appendChild(commonElem);

    const pagesElem = doc.createElement('pages');
    let i = 0;
    while (font.pageTextures[i]) {
        createPageElem(doc, pagesElem, fontName, i);
        i++;
    }
    fontElem.appendChild(pagesElem);

    const charEntries = Object.entries(font.chars);
    const charsElem = doc.createElement('chars');
    charsElem.setAttribute('count', charEntries.length.toString());
    fontElem.appendChild(charsElem);

    const kerningsData = charEntries
        .map((entry) => {
            const [key, data] = entry;
            createCharElem(doc, charsElem, key, data);
            createLetterComment(doc, charsElem, key);
            return collectKernings(key, data.kerning);
        })
        .filter(Boolean)
        .flat();

    if (kerningsData.length !== 0) {
        const kerningsElem = doc.createElement('kernings');
        kerningsElem.setAttribute('count', kerningsData.length.toString());
        fontElem.appendChild(kerningsElem);
        kerningsData.forEach((item) => {
            createKerningElem(doc, kerningsElem, item);
        });
    }

    const serializer = new XMLSerializer();
    const xmlString = '<?xml version="1.0"?>' + serializer.serializeToString(doc);
    return prettifyXml(xmlString);
}

function createPageElem(doc, parent, prefix, index) {
    const suffix = index === 0 ? '' : `_${index}`;
    const pageElem = doc.createElement('page');
    pageElem.setAttribute('id', index);
    pageElem.setAttribute('file', `${prefix}${suffix}.png`);
    parent.appendChild(pageElem);
}

function createLetterComment(doc, parent, key) {
    const letter = String.fromCodePoint(+key);
    const comment = doc.createComment(` ${letter} `);
    parent.appendChild(comment);
}

function createCharElem(doc, parent, key, data) {
    const { page, texture, xAdvance, xOffset, yOffset } = data;
    const { x, y, width, height } = texture.orig;
    const charElem = doc.createElement('char');
    charElem.setAttribute('id', key);
    charElem.setAttribute('x', x);
    charElem.setAttribute('y', y);
    charElem.setAttribute('width', width);
    charElem.setAttribute('height', height);
    charElem.setAttribute('page', page);
    charElem.setAttribute('xadvance', xAdvance);
    charElem.setAttribute('xoffset', xOffset);
    charElem.setAttribute('yoffset', yOffset);
    parent.appendChild(charElem);
}

function createKerningElem(doc, parent, data) {
    const { first, second, amount } = data;
    const kerningElem = doc.createElement('kerning');
    kerningElem.setAttribute('first', first);
    kerningElem.setAttribute('second', second);
    kerningElem.setAttribute('amount', amount);
    parent.appendChild(kerningElem);
}

function collectKernings(char, kerning) {
    if (!kerning) {
        return null;
    }
    const keys = Object.keys(kerning);
    if (keys.length === 0) {
        return null;
    }
    return keys.map((key) => {
        return {
            first: char,
            second: key,
            amount: kerning[key],
        };
    });
}
