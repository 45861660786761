import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Footer from './components/Footer';
import Editor from './components/Editor';
import Header from './components/Header';
import { useAppTheme } from './hooks/useAppTheme';
import { useExternalConfig } from './hooks/useExternalConfig';
import { decodeData } from './utils';

export default function App() {
    const [theme] = useAppTheme();
    const setExternalConfig = useExternalConfig();
    const urlParams = new URLSearchParams(window.location.search);
    const configParam = urlParams.get('config');

    if (configParam) {
        const data = decodeData(configParam);
        setExternalConfig(data);
        window.history.pushState({}, '', window.location.origin);
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <Editor />
            <Footer />
        </ThemeProvider>
    );
}
