import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { StrokeWidthItem } from './StrokeWidthItem';
import { ColorStrokeItem } from './ColorStrokeItem';
import { LineJoinItem } from './LineJoinItem';
import { MiterLimitItem } from './MiterLimitItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const name = 'StrokeControl';

export default function StrokeControl({
    handleChange,
    expandedStyle,
    toggleOpenStyle,
}) {
    return (
        <Accordion
            expanded={expandedStyle === name}
            onChange={toggleOpenStyle(name)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`strokeControl-header`}
            >
                <SectionTitle name="Stroke" />
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <ColorStrokeItem handleChange={handleChange} />
                    <StrokeWidthItem handleChange={handleChange} />
                    <LineJoinItem handleChange={handleChange} />
                    <MiterLimitItem handleChange={handleChange} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
