import { createSlice } from '@reduxjs/toolkit';
import { getPersistState } from './persistState';

const sliceName = 'expandedStyle';
const initialState = getPersistState(sliceName, 'FontControl');

export const expandedStyleSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setExpandedStyle: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setExpandedStyle } = expandedStyleSlice.actions;
export const selectExpandedStyle = (state) => state.expandedStyle;
export default expandedStyleSlice.reducer;
