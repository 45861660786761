import React from 'react';
import manifest from '../../../package.json';
import { Link, Typography } from '@material-ui/core';
import { MessageForm } from './MessageForm';

export function FooterContent() {
    return (
        <Typography align="center" variant="body2" color="textSecondary">
            {' © Sergey Chernykh '}
            {new Date().getFullYear()}
            &nbsp;&nbsp;
            {' | '}
            &nbsp;&nbsp;
            {` v${manifest.version} `}
            &nbsp;&nbsp;
            <MessageForm />
            &nbsp;&nbsp;
            {' | '}
            &nbsp;&nbsp;
            <Link
                color="inherit"
                target="_blank"
                title="pixijs.club - use 'BM FONT' button"
                href="https://pixijs.club/"
            >
                Try your font
            </Link>
        </Typography>
    );
}
