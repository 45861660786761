import React from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMetaFileExtension,
    setMetaFileExtension,
} from '../../../redux/fileExtSlice';

export function MetaFileExtensionControl() {
    const metaFileExt = useSelector(selectMetaFileExtension);
    const dispatch = useDispatch();
    const setMetaFileExt = (e) => {
        dispatch(setMetaFileExtension(e.target.value));
    };

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle name="Meta data file extension" />
            </Grid>
            <Grid item xs={12}>
                <Box pb={1}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            name="gradientType"
                            row
                            value={metaFileExt}
                            onChange={setMetaFileExt}
                        >
                            <FormControlLabel
                                value="fnt"
                                control={<Radio color="default" />}
                                label=".fnt"
                            />
                            <FormControlLabel
                                value="xml"
                                control={<Radio color="default" />}
                                label=".xml"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
        </>
    );
}
