import { createSlice } from '@reduxjs/toolkit';
import { getPersistState } from './persistState';

const sliceName = 'metaFileExtension';
const initialState = getPersistState(sliceName, 'xml');

export const fileExtSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setMetaFileExtension: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setMetaFileExtension } = fileExtSlice.actions;
export const selectMetaFileExtension = (state) => state.metaFileExtension;
export default fileExtSlice.reducer;
