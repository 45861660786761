import React from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';
import { useOptionSlice } from '../../../hooks/useOptionSlice';

export function ResolutionControl() {
    const [resolution, setResolution] = useOptionSlice('resolution');
    const handleResolutionChange = (e) => {
        const value = e.target.valueAsNumber || 1;
        setResolution(value);
    };

    return (
        <>
            <Grid item xs={12}>
                <SectionTitle name="Texture resolution" />
            </Grid>
            <Grid item xs={12}>
                <Box pb={1}>
                    <TextField
                        label={`Resolution`}
                        name={`texture_resolution`}
                        type="number"
                        value={resolution}
                        onChange={handleResolutionChange}
                        variant="outlined"
                        fullWidth
                        size="small"
                        inputProps={{
                            min: 0.5,
                            step: 0.5,
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
}
