import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FooterContent } from './footer/FooterContent';

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'fixed',
        bottom: 0,
        padding: theme.spacing(1, 1),
        width: '100vw',
        height: '4vh',
        borderTop: '4px solid #303030',
        backgroundColor:
            theme.palette.type === 'dark'
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
    },
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <FooterContent />
        </footer>
    );
}
