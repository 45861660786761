import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyleSlice } from '../../../hooks/useStyleSlice';

export function ShadowBlurItem() {
    const [dropShadowBlur, setDropShadowAngle] = useStyleSlice(
        'dropShadowBlur'
    );

    const handleShadowBlurChange = (e) => {
        const value = e.target.valueAsNumber || 0;
        setDropShadowAngle(value);
    };

    return (
        <Grid item xs={12}>
            <TextField
                label={`Blur`}
                name={`dropShadowBlur`}
                type="number"
                value={dropShadowBlur}
                onChange={handleShadowBlurChange}
                inputProps={{
                    min: 0,
                    step: 1,
                }}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Grid>
    );
}
