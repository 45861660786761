import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import FillControl from './style-controls/FillControl';
import StrokeControl from './style-controls/StrokeControl';
import ShadowControl from './style-controls/ShadowControl';
import FontControl from './style-controls/FontControl';
import {
    selectExpandedStyle,
    setExpandedStyle,
} from '../../redux/expandedStyleSlice';

export function StylePanel() {
    const expandedStyle = useSelector(selectExpandedStyle);
    const dispatch = useDispatch();
    const toggleOpenStyle = (panel) => () => {
        dispatch(setExpandedStyle(panel));
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FontControl
                    expandedStyle={expandedStyle}
                    toggleOpenStyle={toggleOpenStyle}
                />
            </Grid>
            <Grid item xs={12}>
                <FillControl
                    expandedStyle={expandedStyle}
                    toggleOpenStyle={toggleOpenStyle}
                />
            </Grid>
            <Grid item xs={12}>
                <StrokeControl
                    expandedStyle={expandedStyle}
                    toggleOpenStyle={toggleOpenStyle}
                />
            </Grid>
            <Grid item xs={12}>
                <ShadowControl
                    expandedStyle={expandedStyle}
                    toggleOpenStyle={toggleOpenStyle}
                />
            </Grid>
        </Grid>
    );
}
