import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from '@material-ui/core';
import { SectionTitle } from '../../generics/SectionTitle';

import { ShadowEnableItem } from './ShadowEnableItem';
import { ShadowDistanceItem } from './ShadowDistanceItem';
import { ShadowColorItem } from './ShadowColorItem';
import { ShadowAngleItem } from './ShadowAngleItem';
import { ShadowBlurItem } from './ShadowBlurItem';
import { ShadowAlphaItem } from './ShadowAlphaItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const name = 'ShadowControl';

export default function ShadowControl({
    handleChange,
    expandedStyle,
    toggleOpenStyle,
}) {
    return (
        <Accordion
            expanded={expandedStyle === name}
            onChange={toggleOpenStyle(name)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`shadowControl-header`}
            >
                <SectionTitle name="Shadow" />
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    <ShadowEnableItem handleChange={handleChange} />
                    <ShadowDistanceItem handleChange={handleChange} />
                    <ShadowColorItem handleChange={handleChange} />
                    <ShadowAngleItem handleChange={handleChange} />
                    <ShadowBlurItem handleChange={handleChange} />
                    <ShadowAlphaItem handleChange={handleChange} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
