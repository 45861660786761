import { useState, useEffect } from 'react';
import WebFont from 'webfontloader';
import { GENERIC_FONT_FAMILIES, FONT_API_KEY, EVENTS } from '../constants';
import { useStyleSlice } from './useStyleSlice';
import { emit } from '../EventEmitter';

const FONT_API_URL = `https://www.googleapis.com/webfonts/v1/webfonts?key=${FONT_API_KEY}`;

export function useGoogleFonts() {
    const [selectedFont, setSelectedFont] = useStyleSlice('fontFamily');
    const [availableFonts, setAvailableFonts] = useState(GENERIC_FONT_FAMILIES);
    const [loadedFonts, setLoadedFonts] = useState(GENERIC_FONT_FAMILIES);

    const handleFontUpload = (e) => {
        const file = e.target.files[0];
        const fontName = getNameFromFile(file.name);
        if (loadedFonts.includes(fontName)) {
            setSelectedFont(fontName);
        } else {
            setLocalFont(file).then(() => {
                setSelectedFont(fontName);
                setAvailableFonts((prevState) => {
                    return [fontName, ...prevState];
                });
                setLoadedFonts((prevState) => {
                    return [fontName, ...prevState];
                });
            });
        }
    };

    useEffect(() => {
        if (
            !loadedFonts.includes(selectedFont) &&
            availableFonts.includes(selectedFont)
        ) {
            loadGoogleFont(selectedFont).then(() => {
                setLoadedFonts((prevState) => {
                    return [selectedFont, ...prevState];
                });
                emit(EVENTS.REDRAW);
            });
        }
    }, [selectedFont, availableFonts, loadedFonts]);

    useEffect(() => {
        fetch(FONT_API_URL)
            .then((res) => res.json())
            .then((data) => {
                const googleFonts = data.items.map((item) => item.family);
                setAvailableFonts((prevState) => {
                    return [...prevState, ...googleFonts];
                });
            });
    }, []);

    return [availableFonts, selectedFont, setSelectedFont, handleFontUpload];
}

export function loadGoogleFont(fontName) {
    return new Promise((resolve) => {
        WebFont.load({
            google: {
                families: [fontName],
            },
            active: () => resolve(),
        });
    });
}

function setLocalFont(file) {
    const name = getNameFromFile(file.name);
    return getAsDataURL(file).then((dataUri) => attachFont(name, dataUri));
}

function getNameFromFile(fileName) {
    return fileName.substring(0, fileName.lastIndexOf('.'));
}

function getAsDataURL(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });
}

function attachFont(name, dataUri) {
    return new Promise((resolve) => {
        addFontFamily(name, dataUri);
        WebFont.load({
            custom: {
                families: [name],
            },
            active: () => resolve(name),
        });
    });
}

function addFontFamily(name, dataurl) {
    const sheet = createStyleSheet();
    const rule = getStyleContent(name, dataurl);
    sheet.insertRule(rule, sheet.cssRules.length);
}

function createStyleSheet() {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(''));
    document.head.appendChild(style);
    return style.sheet;
}

function getStyleContent(name, dataurl) {
    return `@font-face {font-family: "${name}"; src: url("${dataurl}");}`;
}
